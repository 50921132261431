
export const marketingAreaList = [
  {
    text: 'Lokal',
    value: 'Lokal'
  },
  {
    text: 'Regional',
    value: 'Regional'
  },
  {
    text: 'Lokal & Regional',
    value: 'Lokal & Regional'
  }
];